<template>
  <div>
    <v-row dense justify="space-between">
      <v-col cols="3">
        <v-autocomplete
          outlined
          dense
          v-model="record.Series"
          :items="series"
          item-text="name"
          item-value="value"
          label="Series"
        ></v-autocomplete>

        <v-text-field outlined dense label="Deposit No."></v-text-field>

        <DatePicker @date="setDate" :myDate="record.DocDueDate" :title="`Considered Until`"></DatePicker>

        <v-autocomplete
          outlined
          dense
          v-model="record.Currency"
          :items="series"
          item-text="name"
          item-value="value"
          label="Deposit Currency"
        ></v-autocomplete>
      </v-col>

      <v-col cols="3">
        <v-autocomplete
          outlined
          dense
          v-model="record.Currency"
          :items="series"
          item-text="name"
          item-value="value"
          label="Account Type"
        ></v-autocomplete>

        <v-text-field outlined dense :append-icon="'mdi-airballoon'"></v-text-field>
        <v-text-field outlined dense label="Deposit Date"></v-text-field>
        <v-text-field outlined dense label="Bank"></v-text-field>
      </v-col>
      <v-col cols="3">
        <v-text-field outlined dense label="Branch"></v-text-field>
        <v-text-field outlined dense label="Account"></v-text-field>
        <v-text-field outlined dense label="Bank Reference"></v-text-field>
        <v-text-field outlined dense label="payer"></v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <!-- tabs start here -->
      <v-tabs background-color="accent" color="white">
        <v-tab>Checks</v-tab>
        <v-tab>Credit Card</v-tab>
        <v-tab>cash</v-tab>
        <!-- v-tab-items -->

        <!-- general -->
        <v-tab-item>
          <v-row dense class="pt-3">
            <v-col cols="12">
              <v-data-table :headers="chequeHeaders"></v-data-table>
            </v-col>
          </v-row>
        </v-tab-item>
        <v-tab-item>
          <v-row dense class="pt-3">
            <v-col cols="12">
              <v-data-table :headers="creditHeaders"></v-data-table>
            </v-col>
          </v-row>
        </v-tab-item>
        <v-tab-item>
          <v-row dense class="pt-3">
            <v-col cols="3">
              <v-text-field outlined dense label="G/L Account" :append-icon="'mdi-airballoon'"></v-text-field>
              <v-text-field outlined dense label="Amount" class="mt-2"></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field outlined dense filled readonly label="Balance"></v-text-field>
            </v-col>
          </v-row>
        </v-tab-item>
      </v-tabs>
    </v-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      series: [],
      record: [],
      date: null,
      chequeHeaders: [
        { text: "Date", value: "NumAtCard" },
        { text: "Cheque", value: "CardName" },
        { text: "Bank", value: "" },
        { text: "Branch", value: "" },
        { text: "Account No.", value: "" },
        { text: "BP/ Account", value: "" },
        { text: "Check Amount", value: "" },
      ],
      creditHeaders: [
        { text: "Voucher No.", value: "" },
        { text: "Date", value: "" },
        { text: "Card Name", value: "" },
        { text: "Ref.", value: "" },
        { text: "Payment Method", value: "" },
        { text: "Total", value: "" },
      ],
    };
  },
  methods: {
    setDate(date) {
      this.date = date;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>